<template>
  <div class="share">
    <el-tabs
      v-model="activeName"
      @tab-click="handleClick">
      <el-tab-pane
        :label="$t('lang.i18n_shareLiveVideo')"
        name="LiveVideo"></el-tab-pane>
      <el-tab-pane
        :label="$t('lang.SocialAccount')"
        name="SocialAccount"></el-tab-pane>
      <keep-alive>
        <router-view
          v-if="jsComplete"
          :debuggerFlag="debuggerFlag"></router-view>
      </keep-alive>
    </el-tabs>
    <div
      id="debuggerBtn"
      @dblclick="showDebugger"></div>
  </div>
</template>
<script>
import { loadMediaJs } from '@/assets/js/loadSudoku.js'
export default {
  data () {
    return {
      activeName: 'LiveVideo',
      jsComplete: false,
      debuggerFlag: false
    }
  },
  created () {
    this.getRinfo()
    window.localStorage.setItem('mediaDebugger', false)
  },
  mounted () {
    let tabKey = window.location.hash
    this.activeName = tabKey.includes('SocialAccount') ? 'SocialAccount' : 'LiveVideo'
  },
  // 离开界面时关闭websocket
  beforeRouteLeave (to, from, next) {
    if (window.SM) SM.__proto__.stopSocket()
    next()
  },
  methods: {
    getRinfo () {
      loadMediaJs(() => {
        if (window.mediaObj) {
          mediaObj.platform = 'cc' // 集成平台
          mediaObj.page = 'share'
          mediaObj.supportPlatform = this.$store.state.common.baseUrl.mediaControl.demandPlatform
          mediaObj.loginUrl = `${window.location.protocol}//${JSON.parse(window.localStorage.newCCInitConfig).urlInfo.tvuccUrl}` // 登出地址
          mediaObj.displayType = 'R'
          this.jsComplete = true
          SM.__proto__.socketRList(obj => {
            let a = this.findComponentDownward(this, 'LiveVideo')
            if (a && a.upDataRInfo) {
              a.upDataRInfo(obj)
            }
          })
        }
      })
    },
    // tab切换
    handleClick (tab, event) {
      this.$router.push({ name: tab.name })
      this.activeName = tab.name
    },
    // 找任意一个子组件，参数，当前组件，子组件的name属性
    findComponentDownward (context, componentName) {
      const childrens = context.$children
      let children = null
      if (childrens.length) {
        for (const child of childrens) {
          const name = child.$options.name
          if (name === componentName) {
            children = child
            break
          } else {
            children = this.findComponentDownward(child, componentName)
            if (children) break
          }
        }
      }
      return children
    },
    // 是否开启debugger模式
    showDebugger () {
      this.debuggerFlag = !this.debuggerFlag
      window.localStorage.setItem('mediaDebugger', this.debuggerFlag)
    }
  },
  watch: {
    $route (to, from) { // 切换及后退会触发路由变化
      this.activeName = to.name == 'SocialAccount' ? 'SocialAccount' : 'LiveVideo'
    }
  }
}
</script>
<style lang="less" scoped>
.share {
  height: 100%;
  max-width: 1310px;
  margin: 0 auto;
  padding: 0 10px;
  padding-top: 36px;
  position: relative;
  #debuggerBtn {
    height: 30px;
    width: calc(100% - 20px);
    position: absolute;
    bottom: 0;
  }
}
</style>
